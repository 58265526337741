import { styled } from '@stitches/react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
  useState,
} from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import useMediaQuery from 'hooks/useMediaQuery'

const Overlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: '$neutralBg',
  position: 'fixed',
  inset: 0,
  zIndex: 5000,
})

const AnimatedOverlay = forwardRef<
  ElementRef<typeof Overlay>,
  ComponentPropsWithoutRef<typeof Overlay>
>(({ ...props }, forwardedRef) => (
  <Overlay {...props} forceMount asChild>
    <motion.div
      ref={forwardedRef}
      transition={{ duration: 0.5 }}
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: props['style']?.opacity }}
      exit={{ opacity: 0 }}
    />
  </Overlay>
))

const Content = styled(DialogPrimitive.Content, {
  backgroundColor: '$neutralBg',
  borderRadius: 8,
  $$shadowColor: '$colors$gray7',
  boxShadow: 'box-shadow: 0px 2px 16px $$shadowColor',
  border: '1px solid $gray7',
  position: 'fixed',
  transform: 'translateX(-50%)',
  left: '50%',
  maxWidth: 516,
  top: '100%',
  width: '100%',
  zIndex: 5000,
  overflowY: 'auto',
  '&:focus': { outline: 'none' },
  '@media(max-width: 520px)': {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
  }
})

const AnimatedContent = forwardRef<
  ElementRef<typeof DialogPrimitive.DialogContent>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.DialogContent>
>(({ children, ...props }, forwardedRef) => {
  const isMobile = useMediaQuery('(max-width: 520px)')

  const animation = isMobile
    ? {
        initial: {
          opacity: 0,
          bottom: '-100%', 
          top: 'auto',
          left: 0,
        },
        animate: {
          opacity: 1,
          bottom: 0,
          top: 'auto',
          left: 0,
        },

        exit: {
          opacity: 0,
          bottom: '-100%',
          top: 'auto',
          left: 0,
        },
      }
    : {
        initial: {
          opacity: 0,
          top: '14%',
          transform: 'translateX(-50%)',
        },
        animate: {
          opacity: 1,
          top: '9%',
          transform: 'translateX(-50%)',
        },

        exit: {
          opacity: 0,
          top: '14%',
          transform: 'translateX(-50%)',
        },
      }

  return (
    <Content forceMount asChild {...props}>
      <motion.div
        key={isMobile + 'modal'}
        ref={forwardedRef}
        transition={{ type: isMobile ? 'tween' : 'spring', duration: 0.5 }}
        {...animation}
      >
        {children}
      </motion.div>
    </Content>
  )
})

type Props = {
  trigger?: ReactNode
  portalProps?: DialogPrimitive.PortalProps
  overlayProps?: DialogPrimitive.DialogOverlayProps
  open?: ComponentPropsWithoutRef<typeof DialogPrimitive.Root>['open']
  istransform?: boolean
  onOpenChange: ComponentPropsWithoutRef<
    typeof DialogPrimitive.Root
  >['onOpenChange']
}

const Dialog = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & Props
>(
  (
    {
      children,
      trigger,
      portalProps,
      overlayProps,
      open,
      istransform = true, // Optional: default to true
      onOpenChange,
      ...props
    },
    forwardedRef
  ) => {
    const [_open, _onOpenChange] = useState(false)
    const transformed = istransform? 'translateX(-50%)' : 'translateX(0%)' 
    return (
      <DialogPrimitive.Root
        onOpenChange={onOpenChange || _onOpenChange}
        open={open || _open}
      >
        {trigger && (
          <DialogPrimitive.DialogTrigger asChild>
            {trigger}
          </DialogPrimitive.DialogTrigger>
        )}
        <AnimatePresence>
          {open && (
            <DialogPrimitive.DialogPortal forceMount {...portalProps}>
              <AnimatedOverlay style={{ opacity: 1 }} {...overlayProps} />
              <AnimatedContent style={{transform:transformed}} ref={forwardedRef} {...props} forceMount>
                {children}
              </AnimatedContent>
            </DialogPrimitive.DialogPortal>
          )}
        </AnimatePresence>
      </DialogPrimitive.Root>
    )
  }
)

export { Dialog, Content, AnimatedContent, Overlay, AnimatedOverlay }