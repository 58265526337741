import { cronos, cronosTestnet } from 'wagmi/chains'
import { useMarketplaceChain } from 'hooks'
import { zeroAddress } from 'viem'

export default function () {
  const chain = useMarketplaceChain()
  const CronosChains: number[] = [cronos.id, cronosTestnet.id]

  if (!chain || !chain.nativeCurrency || CronosChains.includes(chain.id)) {
    return {
      name: 'Cronos',
      symbol: 'CRO',
      decimals: 18,
      address: zeroAddress,
      chainId: chain?.id || cronos.id,
    }
  } else {
    return {
      ...chain.nativeCurrency,
      address: zeroAddress,
      chainId: chain.id,
    }
  }
}
