import React, { FC } from 'react'
import { styled } from '../../stitches.config'
import { StyledComponent } from '@stitches/react/types/styled-component'
import { zeroAddress } from 'viem'
import wrappedContracts from 'utils/wrappedContracts'

type Props = {
  address: string
  chainId?: number
} & Parameters<StyledComponent>['0']

const StyledImg = styled('img', {})

const CryptoCurrencyIcon: FC<Props> = ({
  address = zeroAddress,
  chainId,
  css,
}) => {

  const tokenImg = (address.toLowerCase() === zeroAddress || address.toLowerCase() === wrappedContracts[25].toLocaleLowerCase())?
  `/icons/currency/${address}.png` : `https://dd.dexscreener.com/ds-data/tokens/cronos/${address}.png?size=lg`;

  return (
    <StyledImg
      src={tokenImg}
      css={css}
    />
  )
}

export default CryptoCurrencyIcon