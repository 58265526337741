const wrappedContracts: Record<number, string> = {
  1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', //mainnet
  5: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', //goerli
  25: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', //cronos
  10: '0x4200000000000000000000000000000000000006', //optimism
  56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', //bnb
  137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270', //polygon
  324: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91', //zksync
  338: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', //cronos testnet
}

export default wrappedContracts
