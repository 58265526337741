import { UserAssets } from "config/types/types";
import useSWR, { SWRConfiguration } from "swr";
import { createAuthHeaders } from "utils/auth";

export default function useUserAssets(address: string | undefined, options?: SWRConfiguration) {
  const { data, mutate, isValidating, isLoading } = useSWR<UserAssets>(
    (address && address !== '' ) ? `/api/user/assets?address=${address}` :  null,
    async (url: string) => {
      try {
        const response = await fetch(url, {
          headers: createAuthHeaders() // Include authentication token in headers
        });
        if (!response.ok) {
          throw new Error('Failed to fetch user assets');
        }
        return await response.json();
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    {
      revalidateOnFocus: true,
      revalidateIfStale: true,
      revalidateOnReconnect: true,
      ...options
    }
  );

  // Add a function to refetch the data
  const refetchData = async () => {
    await mutate(); // Mutate without passing data to refetch
  };

  return {
    data,
    mutate,
    isValidating,
    isLoading,
    refetchData
  };
}