import { Anchor, Box, Button, Flex, Text } from 'components/primitives'
import { Avatar } from 'components/primitives/Avatar'
import * as RadixDialog from '@radix-ui/react-dialog'
import {
  faBars,
  faChevronDown,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import Image from 'next/image'
import { useAccount, useDisconnect } from 'wagmi'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { FullscreenModal } from 'components/common/FullscreenModal'
import { useENSResolver } from 'hooks'
import ThemeSwitcher from 'components/navbar/ThemeSwitcher'
import useCroId from 'hooks/useCronosID'
import { Address, Chain } from 'viem'
import { faDiscord, faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { Collapsible } from 'components/primitives/Collapsible'

const HamburgerMenu = () => {
  const { address, isConnected, chain} = useAccount()
  const {
    avatar: ensAvatar,
  } = useENSResolver(address)
  const { disconnect } = useDisconnect()

  const {shortEnsName, shortAddress} = useCroId(address as Address, chain as Chain)

  const trigger = (
    <Button
      css={{ justifyContent: 'center', width: '44px', height: '44px' }}
      type="button"
      size="small"
      color="ghost"
    >
      <FontAwesomeIcon size={'xl'} icon={faBars} width={28} height={28} />
    </Button>
  )

  return (
    <FullscreenModal trigger={trigger}>
      {' '}
      <Flex
        css={{
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Flex
          css={{
            py: '$4',
            px: '$4',
            width: '100%',
            borderBottom: '1px solid $gray4',
          }}
          align="center"
          justify="between"
        >
          <Link href="/">
            <Box css={{ width: 46, cursor: 'pointer' }}>
              <Image
                src="/logo.png"
                width={36}
                height={36}
                alt="ROBIN Logo"
              />
            </Box>
          </Link>
          <RadixDialog.Close>
            <Flex
              css={{
                justifyContent: 'center',
                width: '44px',
                height: '44px',
                alignItems: 'center',
                borderRadius: 8,
                backgroundColor: '$ghost',
                color: '$gray12',
                '&:hover': {
                  backgroundColor: '$gray4',
                },
              }}
            >
              <FontAwesomeIcon icon={faXmark} width={16} height={16} />
            </Flex>
          </RadixDialog.Close>
        </Flex>
        {isConnected ? (
          <Flex
            css={{
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height: '100%',
              py: '$5',
              px: '$4',
            }}
          >
            
              <Flex
                css={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  pb: '$4',
                }}
              >
                <Flex css={{ alignItems: 'center' }}>
                  {ensAvatar ? (
                    <Avatar size="medium" src={ensAvatar} />
                  ) : (
                    <Jazzicon
                      diameter={36}
                      seed={jsNumberForAddress(address as string)}
                    />
                  )}
                  <Text style="subtitle1" css={{ ml: '$2' }}>
                    {shortEnsName ? shortEnsName : shortAddress}
                  </Text>
                </Flex>
              </Flex>
            
            {/*
            <Link href={`/${routePrefix}/collection-rankings`} legacyBehavior>
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Explore
              </Text>
            </Link>
            <Link href="/cronos/collections/trending" legacyBehavior>
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Trending Collections
              </Text>
            </Link>

            <Link href="/cronos/mints/trending" legacyBehavior>
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Trending Mints
              </Text>
            </Link>
   
            <Link href="/launchpad" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">Launchpad</Text>
              </Flex>
            </Link>

            <Link href="/mint" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">NFT Launchpad</Text>
              </Flex>
            </Link>
            <Link href="/nft-staking" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">NFT Staking</Text>
                <Text style="body3" color="subtle">
                  Stake your NFTs for daily rewards
                </Text>
              </Flex>
            </Link>
            <Link href="/profile?tab=presale" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">Create Launchpad</Text>
              </Flex>
            </Link>
              */}
            <Link href="https://obsidian.finance/" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">Obsidian Swap</Text>
              </Flex>
            </Link>
            <Link href="/launchpad" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">Tokens Launchpad</Text>
              </Flex>
            </Link>
            <Link href="/mint" legacyBehavior>
              <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">NFT Mint</Text>
                </Flex>
            </Link>
            <Link href="/nft-staking" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">NFT Staking</Text>
                <Text style="body3" color="subtle">
                  Stake your NFTs for daily rewards
                </Text>
              </Flex>
            </Link>
            <Link href="/staking" legacyBehavior>
              <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">Token Staking</Text>
                  <Text style="body3" color="subtle">
                    Diamond Hands Token Staking
                  </Text>
                </Flex>
            </Link>
            <Link href="/swap" legacyBehavior>
              <Flex
                direction="column"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  gap: '$1',
                }}
              >
                <Text style="subtitle1">Cross-Chain Swap</Text>
                <Text style="body3" color="subtle">
                  Swap from other chains
                </Text>
              </Flex>
            </Link>
            <Collapsible
              trigger={
                <Flex
                  justify="between"
                  css={{
                    width: '100%',
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                  }}
                >
                  <Text style="subtitle1">About</Text>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    width={20}
                    height={20}
                    style={{
                      marginLeft: 10, // Add some space between the text and the icon
                    }}
                  />
                </Flex>
              }
            >
            <Flex
              direction="column"
              css={{
                backgroundColor: '$gray2',
                px: '$4',
              }}
            >
              <Link href={`/litepaper`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                  }}
                >
                  Litepaper
                </Text>
              </Link>
              <Link href={`/tokenomics`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                  }}
                >
                  Tokenomics
                </Text>
              </Link>
              <Link href={`/roadmap`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                  }}
                >
                  Roadmap
                </Text>
              </Link>
            </Flex>
          </Collapsible>
            <Button
              size="large"
              css={{ my: '$4', mt:'$5', justifyContent: 'center' }}
              color="gray3"
              onClick={() => disconnect()}
            >
              Disconnect Wallet
            </Button>
          </Flex>
        ) : (
          <Flex
            direction="column"
            justify="between"
            css={{
              height: '100%',
              pb: '$5',
              px: '$4',
            }}
          >
            <Flex direction="column">
              <Link href="/launchpad" legacyBehavior>
                <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">Tokens Launchpad</Text>
                </Flex>
              </Link>
              <Link href="/mint" legacyBehavior>
                <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">NFT Mint</Text>
                </Flex>
              </Link>
              <Link href="/nft-staking" legacyBehavior>
                <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">NFT Staking</Text>
                </Flex>
              </Link>
              <Link href="/staking" legacyBehavior>
                <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">Token Staking</Text>
                  <Text style="body3" color="subtle">
                    Diamond Hands Token Staking
                  </Text>
                </Flex>
              </Link>
              <Link href="/swap" legacyBehavior>
                <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">Cross-chain Swap</Text>
                  <Text style="body3" color="subtle">
                    Buy $ROBIN using ETH
                  </Text>
                </Flex>
              </Link>
            <Collapsible
              trigger={
                <Flex
                  justify="between"
                  css={{
                    width: '100%',
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                  }}
                >
                  <Text style="subtitle1">About</Text>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    width={20}
                    height={20}
                    style={{
                      marginLeft: 10, // Add some space between the text and the icon
                    }}
                  />
                </Flex>
              }
            >
            <Flex
              direction="column"
              css={{
                backgroundColor: '$gray2',
                px: '$4',
              }}
            >
              <Link href={`/litepaper`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                  }}
                >
                  Litepaper
                </Text>
              </Link>
              <Link href={`/tokenomics`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                  }}
                >
                  Tokenomics
                </Text>
              </Link>
              <Link href={`/roadmap`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                  }}
                >
                  Roadmap
                </Text>
              </Link>
            </Flex>
          </Collapsible>
              {/*
              <Link href="/mint" legacyBehavior>
                <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">NFT Launchpad</Text>
                </Flex>
              </Link>
              <Link href="/nft-staking" legacyBehavior>
                <Flex
                  direction="column"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">NFT Staking</Text>
                  <Text style="body3" color="subtle">
                    Stake your NFTs for daily rewards
                  </Text>
                </Flex>
              </Link>
            {/*
              <Link href="/" legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Explore
                </Text>
              </Link>
              <Link href={`/portfolio/${address || ''}`} legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Portfolio
                </Text>
              </Link>
              <Anchor
                href="https://docs.robin.space/docs"
                target="_blank"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                  width: '100%',
                }}
              >
                <Text style="subtitle1">Docs</Text>
              </Anchor>
              */}
            </Flex>
            <Box css={{mt:'$4'}}>
              <ConnectWalletButton />
            </Box>
          </Flex>
        )}
        <Flex
          css={{
            pt: '24px',
            pb: '$5',
            px: '$4',
            gap: '$4',
            width: '100%',
            borderTop: '1px solid $gray4',
          }}
        >
          <a href="https://twitter.com/robinCronos" target="_blank">
            <Button
              css={{ justifyContent: 'center', width: '44px', height: '44px' }}
              type="button"
              size="small"
              color="gray3"
            >
              <FontAwesomeIcon icon={faXTwitter} width={20} height={20} />
            </Button>
          </a>
          <a href=" https://t.me/robinCronos" target="_blank">
            <Button
              css={{ justifyContent: 'center', width: '44px', height: '44px' }}
              type="button"
              size="small"
              color="gray3"
            >
              <FontAwesomeIcon icon={faTelegram} width={20} height={20} />
            </Button>
          </a>
          <a href="https://discord.gg/HSDJPQTeDX" target="_blank">
            <Button
              css={{ justifyContent: 'center', width: '44px', height: '44px' }}
              type="button"
              size="small"
              color="gray3"
            >
              <FontAwesomeIcon icon={faDiscord} width={20} height={20} />
            </Button>
          </a>
        </Flex>
      {/*
        <Flex
          css={{
            pt: '$1',
            pb: '$2',
            px: '$4',
            width: '100%',
          }}
        >
          <ThemeSwitcher />
        </Flex>
        */}
      </Flex>
    </FullscreenModal>
  )
}

export default HamburgerMenu