import { useRef } from 'react'
import { Box, Card, Flex, Text } from '../primitives'
import { useRouter } from 'next/router'
import { useHotkeys } from 'react-hotkeys-hook'
import Link from 'next/link'
import Image from 'next/image'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import NavItem from './NavItem'
import HamburgerMenu from './HamburgerMenu'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import { useMarketplaceChain, useMounted } from '../../hooks'
import { useAccount } from 'wagmi'
import { AccountSidebar } from 'components/navbar/AccountSidebar'
import * as HoverCard from '@radix-ui/react-hover-card'

import MenuBarPrice from 'components/primitives/PriceDisplay'
import useDexscreener from 'hooks/useDexscreener'
import { Dropdown, DropdownMenuItem } from 'components/primitives/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export const NAVBAR_HEIGHT = 81
export const NAVBAR_HEIGHT_MOBILE = 77

const Navbar = () => {
  const { theme } = useTheme()
  const { isConnected } = useAccount()
  const isMobile = useMediaQuery({ query: '(max-width: 960px' })
  const isMounted = useMounted()
  const { routePrefix } = useMarketplaceChain()
  const appName = process.env.NEXT_PUBLIC_APP_NAME || 'ROBIN'
  let searchRef = useRef<HTMLInputElement>(null)
  const token = process.env.NEXT_PUBLIC_CORE_TOKEN_ADDRESS as string
  const { data: usdData } = useDexscreener([token]);
  const tokenPrice = usdData[0]?.price || 0

  const router = useRouter()
  useHotkeys('meta+k', (e) => {
    e.preventDefault()
    if (searchRef?.current) {
      searchRef?.current?.focus()
    }
  })

  const isActive = (href: string) => router.pathname === href;

  if (!isMounted) {
    return null
  }

  return isMobile ? (
    <Flex
      css={{
        height: NAVBAR_HEIGHT_MOBILE,
        px: '$4',
        width: '100%',
       // borderBottom: '1px solid $gray4',
        zIndex: 999,
      //  background: '$slate1',
        background: '$neutralBg',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between"
    >
      <Box css={{ flex: 1 }}>
        <Flex align="center">
          <Link href={`/${routePrefix}`}>
            <Box css={{ width: 46, cursor: 'pointer' }}>
              <Image
                src="/logo.png"
                width={36}
                height={36}
                alt="Logo"
              />
            </Box>
          </Link>
          <Flex
              align="center"
              css={{
                gap: '$3',
              }}
            >
            <MenuBarPrice amount={tokenPrice} />
          </Flex>
        </Flex>
      </Box>
      <Flex align="center" css={{ gap: '$3' }}>
        {/*
        <MobileSearch key={`${router.asPath}-search`} />
         <CartButton /> 
        */}
        {isConnected ? (
          <AccountSidebar />
        ) : (
          <Box css={{ maxWidth: '185px' }}>
            <ConnectWalletButton />
          </Box>
        )}
        <HamburgerMenu key={`${router.asPath}-hamburger`} />



      </Flex>
    </Flex>
  ) : (
    <Flex
      css={{
        height: NAVBAR_HEIGHT,
        px: '$5',
        '@xl': {
          px: '$6',
        },
        width: '100%',
        // maxWidth: 1920,
        mx: 'auto',
        // borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: '$neutralBg',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between"
    >
      <Box
        css={{
          flex: 'unset',
          '@bp1300': {
            flex: 1,
          },
        }}
      >
        <Flex align="center">
          <Link href={`/${routePrefix}`}>
          <Box css={{ cursor: 'pointer', alignItems:'center', display: 'flex', gap:8 }}>
              <Image
                src="/logo.png"
                width={36}
                height={36}
                alt="Logo"
              />
              <Text style={'h5'}>
                {appName}
              </Text>
            </Box>
          </Link>
          <Flex
            align="center"
            css={{
              gap: '$5',
              ml: '$3',
            }}
          >
            <MenuBarPrice amount={tokenPrice} />
          </Flex>
          {/*
          <Flex
            align="center"
            css={{
              gap: '$5',
              ml: '$5',
            }}
          >
            <Link href={`/${routePrefix}`}>
              <NavItem>Explore</NavItem>
            </Link>

            <HoverCard.Root openDelay={200}>
              <HoverCard.Trigger>
                <NavItem>Trending</NavItem>
              </HoverCard.Trigger>
              <HoverCard.Content sideOffset={24} align="start">
                <Card css={{ p: 24, width: 240, border: '1px solid $gray4' }}>
                  <Flex css={{ gap: '$4' }} direction="column">
                    <Link href={`/${routePrefix}/collections/trending`}>
                      <NavItem>Collections</NavItem>
                    </Link>
                    <Link href={`/${routePrefix}/mints/trending`}>
                      <NavItem>Mints</NavItem>
                    </Link>
                  </Flex>
                </Card>
              </HoverCard.Content>
            </HoverCard.Root>

            {false && (
              <Link href={`/${routePrefix}/collections/minting`}>
                <NavItem>Mints</NavItem>
              </Link>
            )}
            {false && (
              <Link href="/swap">
                <NavItem>Tokens</NavItem>
              </Link>
            )}
          </Flex>
            */}
        </Flex>
      </Box>

      <Flex
        css={{
          gap: '$3',
          flex: 'unset',
          '@bp1300': {
            flex: 1,
          },
        }}
        justify="end"
        align="center"
      >
        <Flex css={{ gap: '$5', mr: 12 }}>
            <Link href={`https://obsidian.finance/`}>
              <Flex css={{ mr: '$2' }} direction={"row"}>
                <NavItem >Swap</NavItem>
              </Flex>
            </Link>
            <Link href={`/swap`}>
              <Flex css={{ mr: '$2' }} direction={"row"}>
                <NavItem isActive={isActive(`/swap`)}>XY Swap</NavItem>
              </Flex>
            </Link>
            <HoverCard.Root openDelay={200}>
              <HoverCard.Trigger>
                <NavItem>  
                  <Flex as="span" align="center">
                    {`Staking`}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      width={16}
                      height={16}
                      style={{
                        marginLeft: 5,
                        display: 'inline-block',
                      }}
                    />
                  </Flex>
                </NavItem>
              </HoverCard.Trigger>
              <HoverCard.Content sideOffset={24} align="start">
                <Card css={{ p: 24, width: 240, border: '1px solid $gray4' }}>
                  <Flex css={{ gap: '$4' }} direction="column">
                    <Link href={`/staking`}>
                      <NavItem isActive={isActive(`/staking`)}>Tokens</NavItem>
                    </Link>
                    <Link href={`/nft-staking`}>
                      <NavItem isActive={isActive(`/nft-staking`)}>NFTs</NavItem>
                    </Link>
                  </Flex>
                </Card>
              </HoverCard.Content>
            </HoverCard.Root>
            <HoverCard.Root openDelay={200}>
              <HoverCard.Trigger>
                <NavItem>  
                  <Flex as="span" align="center">
                    {`Launchpad`}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      width={16}
                      height={16}
                      style={{
                        marginLeft: 5,
                        display: 'inline-block',
                      }}
                    />
                  </Flex>
                </NavItem>
              </HoverCard.Trigger>
              <HoverCard.Content sideOffset={24} align="start">
                <Card css={{ p: 24, width: 240, border: '1px solid $gray4' }}>
                  <Flex css={{ gap: '$4' }} direction="column">
                    <Link href={`/launchpad`}>
                      <NavItem isActive={isActive(`/launchpad`)}>Tokens</NavItem>
                    </Link>
                    <Link href={`/mint`}>
                      <NavItem isActive={isActive(`/mint`)}>NFTs</NavItem>
                    </Link>
                  </Flex>
                </Card>
              </HoverCard.Content>
            </HoverCard.Root>
            <HoverCard.Root openDelay={200}>
              <HoverCard.Trigger>
                <NavItem>  
                  <Flex as="span" align="center">
                    {`About`}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      width={16}
                      height={16}
                      style={{
                        marginLeft: 5,
                        display: 'inline-block',
                      }}
                    />
                  </Flex>
                </NavItem>
              </HoverCard.Trigger>
              <HoverCard.Content sideOffset={24} align="start">
                <Card css={{ p: 24, width: 240, border: '1px solid $gray4' }}>
                  <Flex css={{ gap: '$4' }} direction="column">
                    <Link href={`/litepaper`}>
                      <NavItem isActive={isActive(`/litepaper`)}>Litepaper</NavItem>
                    </Link>
                    <Link href={`/tokenomics`}>
                      <NavItem isActive={isActive(`/tokenomics`)}>Tokenomics</NavItem>
                    </Link>
                    <Link href={`/roadmap`}>
                      <NavItem isActive={isActive(`/roadmap`)}>Roadmap</NavItem>
                    </Link>
                  </Flex>
                </Card>
              </HoverCard.Content>
            </HoverCard.Root>
           {/* 
          <Dropdown
            modal={false}
            trigger={
              <NavItem>
                <Flex as="span" align="center">
                  {`More`}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    width={16}
                    height={16}
                    style={{
                      marginLeft: 5,
                      display: 'inline-block',
                    }}
                  />
                </Flex>
              </NavItem>
            }
            contentProps={{
              asChild: true,
              forceMount: true,
              sideOffset: 35,
            }}
          >
            <DropdownMenuItem
              as={Link}
              href="/fortune"
              css={{
                display: 'flex',
                py: '$3',
                width: '100%',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <Image
                src="/images/fortune.png"
                width={20}
                height={20}
                objectFit="contain"
                alt="Fortune"
              />
              {`Fortune`}
            </DropdownMenuItem>
            <DropdownMenuItem
              as={Link}
              href="https://smartnft.nftearth.exchange"
              target="_blank"
              css={{
                display: 'flex',
                py: '$3',
                width: '100%',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <Image
                src="/images/smartnft.png"
                width={20}
                height={20}
                objectFit="contain"
                alt="SmartNFT"
              />
              {`SmartNFT`}
            </DropdownMenuItem>
          </Dropdown>
            */}
        </Flex>
        {isConnected ? (
          <AccountSidebar />
        ) : (
          <Box css={{ maxWidth: '185px' }}>
            <ConnectWalletButton />
          </Box>
        )}
        {/*
        <CartButton /> 
      */}
      </Flex>
    </Flex>
  )
}

export default Navbar