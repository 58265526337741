import useSWR, { SWRConfiguration } from "swr";

export default function useProfile(address: string | undefined, options?: SWRConfiguration) {
  const { data, mutate, isValidating, isLoading } = useSWR(
    (address !== '' ) ? `/api/user/profile?address=${address}` :  null,
    (url: string) => {
      if (!address) {
        return null
      }
      return fetch(url).then((response) => response.json())
    },
    {
      revalidateOnFocus: true,
      revalidateIfStale: true,
      revalidateOnReconnect: true,
      ...options
    }
  )

  // Add a function to refetch the data
  const refetchData = async () => {
    await mutate(); // Mutate without passing data to refetch
  };

  return {
    data,
    mutate,
    isValidating,
    isLoading,
    refetchData
  };
}