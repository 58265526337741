import useSWR from 'swr/immutable';
import usdcContracts from 'utils/usdcContracts';
import wrappedContracts from 'utils/wrappedContracts';
import { zeroAddress } from 'viem';
import { cronos } from 'wagmi/chains';

interface Token {
  price: number;
  symbol: string;
  id: string;
}

export default function useTokenPrices(
  tokenAddresses: string[] = [],
): { data: Token[]; error: any } {
  const baseUrl = 'https://api.dexscreener.com/latest/dex/tokens/';

  async function fetchTokenPrices(url: string) {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  }

  // Filter out the Ethereum zero address
  const filteredAddresses = tokenAddresses.filter(address => address !== zeroAddress);

  // Construct the token URL using filtered addresses
  const tokenUrl = filteredAddresses.length > 0 ? `${baseUrl}${filteredAddresses.join(',')}` : null;

  const { data, error } = useSWR(tokenUrl, fetchTokenPrices, { refreshInterval: 300000 });

  if (error) {
    console.error('Error fetching token prices:', error);
  }

  const tokens: Token[] = data?.pairs
  ?.filter((pair: any) => {
      if (pair.baseToken.address === wrappedContracts[cronos.id]) {
          return pair.quoteToken.address === usdcContracts[cronos.id];
      } else {
          return pair.quoteToken.address === wrappedContracts[cronos.id];
      }
  })
  .map((pair: any) => ({
      price: parseFloat(pair.priceUsd),
      symbol: pair.baseToken.symbol,
      id: pair.baseToken.address,
  })) || [];


  return { data: tokens, error };
}