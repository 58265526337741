import React from 'react';
import { keyframes, styled } from '../../stitches.config';
import { Flex, Text } from './index'
import Link from 'next/link';

  const Price = styled('div', {
    position: 'relative', 
    gap:'$1',
    display: 'flex',
    padding:'$2',
    alignItems:'center',
    marginTop: 2,
  });

  const pulsate = keyframes({
    '0%': { transform: 'scale(0.1, 0.1)', opacity: 0.0 },
    '50%': { opacity: 1.0 },
    '100%': { transform: 'scale(1.2, 1.2)', opacity: 0.0 },
  });
  
  
  const Dot = styled('div', {
    backgroundColor: '$green7',
    borderRadius: '50%',
    display: 'block',
    position:'absolute',
    width: '10px',
    height: '10px',
    
    '&:before': {
      backgroundColor: 'rgba(255, 255, 255, 0.16)',
      wordWrap: 'break-word'
    },
  
    '&:after': {
      borderWidth: 0,
      borderStyle: 'solid',
      boxSizing: 'border-box'
    },
  });
  
  const PulsateIcon = styled('div', {
    position:'absolute',
    display: 'block',
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: '$green7',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    top: '-4px',
    left: '-4px',
    animationName: `${pulsate}`,
    animationDuration: '1.5s',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
  });


const token = process.env.NEXT_PUBLIC_CORE_TOKEN_ADDRESS as string
const MenuBarPrice = ({ amount }:any) => {
  return (
    <Price>
      <Flex css={{position: 'relative', width:10, height:10}}>
        <Dot />
        <PulsateIcon/>
      </Flex>
      <Link href={`https://dexscreener.com/cronos/${token}`} target="_blank">
        <Text style={'subtitle2'} css={{marginLeft:5, color:'$gray11'}}>${amount.toFixed(6)}</Text>
      </Link>
    </Price>
  );
};

export default MenuBarPrice;