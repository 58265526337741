import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export default function (timestamp?: number) {
  const [timeSince, setTimeSince] = useState('')

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timestamp) {
        setTimeSince(dayjs.unix(timestamp).fromNow())
      } else {
        setTimeSince('')
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timestamp]);

  return timeSince
}
