import useMarketplaceChain from './useMarketplaceChain'
import useSWR from 'swr'

export default () => {
  const { proxyApi } = useMarketplaceChain()

  const path = `${process.env.NEXT_PUBLIC_PROXY_URL}${proxyApi}/chain/stats/v1`

  return useSWR(
    path ? [path] : null,
    null,
    {}
  )
}
