import { Text } from 'components/primitives'
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react'

type NavItemProps = {
  children: ReactNode
  isActive?: boolean
}

const NavItem = forwardRef<
  HTMLParagraphElement,
  ComponentPropsWithoutRef<typeof Text> & NavItemProps
>(({ children, isActive, ...props }, forwardedRef) => (
  <Text
    {...props}
    ref={forwardedRef}
    css={{
      color: isActive? '$gray12' : '$gray11',
      cursor: 'pointer',
      fontWeight: isActive? 700 : 600,
      '&:hover': {
        color: '$gray12',
        fontWeight: 700,
      },
      ...props.css,
    }}
    as="p"
    style="subtitle1"
  >
    {children}
  </Text>
))

export default NavItem